import { FieldError, FieldErrors } from 'react-hook-form/dist/types/errors';
import Messages from 'services/i18n/Messages';

export const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const zipCodeRegex = /([0-9]{5})/;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

// eslint-disable-next-line no-control-regex
export const mailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

function getErrorMessageFromType(error: FieldError) {
  switch (error.type) {
    case 'required':
      return Messages.t('form.error.requierd');
    default:
      return Messages.t('form.error.default');
  }
}

export function getFinalErrorMessage(
  fieldName: string,
  error?: FieldErrors,
  apiErrors?: { [key: string]: string[] },
): string | undefined {
  const fieldError = error ? fieldName.split('.').reduce((a, b) => a?.[b], error) as FieldError : undefined;
  const apiError = apiErrors && apiErrors[fieldName];

  const fieldErrorMessage = fieldError
    && (fieldError.message || getErrorMessageFromType(fieldError));
  // @ts-ignore
  let finalError = fieldErrorMessage || (apiError && Messages.t(`field.error.${apiError[0]}`));
  if (finalError?.includes('\n')) {
    finalError = finalError?.replaceAll('\n', '<br/>');
  }
  return Messages.getHtmlFromStr(finalError || '');
}

export function stringToBoolean(string: any) {
  if (typeof string === 'boolean') {
    return string;
  }
  if (!string || !string.toLowerCase()) {
    return null;
  }
  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return null;
  }
}

export function booleanToString(boolean: any) {
  if (boolean === null || boolean === undefined) {
    return undefined;
  }
  return boolean ? 'true' : 'false';
}

export function stringToNumber(string: any) {
  if (Number.isNaN(string)) {
    return null;
  }
  return parseInt(string, 10);
}

export function validatePhoneNumber(formValue: any) {
  return formValue.startsWith('+');
}

export function validateMailList(formValue: any) {
  if (typeof formValue !== 'string') {
    return null;
  }

  const mails = formValue
    // postal codes are separated by commas
    .split(',')
    // accept control characters
    .map((code) => code.trim())
    // accept empty values
    .filter((code) => code !== '');
  const regex = new RegExp(mailRegex);
  return mails.every((mail) => regex.test(mail));
}

export function splitPostalCodes(zipCodesStr?: string | null) {
  if (typeof zipCodesStr !== 'string') {
    return null;
  }
  const zipCodes = zipCodesStr
    .split(',')
    .map((code) => code.trim())
    .filter((code) => code !== '');

  for (const code of zipCodes) {
    const codeNumber = Number(code);
    if (!Number.isInteger(codeNumber) || codeNumber <= 0) {
      return null;
    }
  }

  return zipCodes;
}

export function validateAddress(formValue: any): boolean {
  if (typeof formValue !== 'string') {
    return false;
  }

  const components = formValue
    // postal codes are separated by commas
    .split(',');
  if (components.length !== 3) {
    return false;
  }
  const cityComponent = components[1].split(' ');
  if (cityComponent.length < 2) {
    return false;
  }
  const zipCode = Number(cityComponent[0]);
  return Number.isInteger(zipCode) && components.every((addressComponent) => addressComponent.trim() !== '');
}

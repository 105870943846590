import { observable, WritableObservable } from 'micro-observables';
import { ReactNode } from 'react';

export type ModalContent = {
  title: string,
  message: ReactNode,
  actionColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  actionMessage?: string,
};

type BoolFunc = (v: boolean) => void;

export class ConfirmationService {
  private modal: WritableObservable<ModalContent | null> = observable(null);

  private resolveFunction?: BoolFunc;

  private rejectFunction?: BoolFunc;

  confirm(modalProp: ModalContent): Promise<boolean> {
    this.modal.set(modalProp);
    if (this.resolveFunction) {
      this.resolveFunction(false);
    }
    return new Promise((resolve, reject) => {
      this.resolveFunction = resolve;
      this.rejectFunction = reject;
    });
  }

  onClose() {
    if (this.resolveFunction) {
      this.resolveFunction(false);
    }
    this.modal.set(null);
  }

  onConfirm() {
    if (this.resolveFunction) {
      this.resolveFunction(true);
    }
    this.modal.set(null);
  }

  getModal() {
    return this.modal.readOnly();
  }
}

const confirmationService = new ConfirmationService();
export default confirmationService;
